<template>
  <div class="page-template">
    <div class="page-template__item page-template_email">
      <div class="page-template__title section-title">Шаблоны писем</div>

      <div class="page-template__description section-description">
        Создавайте свои уникальные шаблоны, для уведомления пользователей.
      </div>

      <div class="page-template__btns">
        <app-button :className="'page-template__btn'" @click="openModalCreateTemplate" :value="'Создать новый шаблон'" />
        <app-button :className="'page-template__btn mode-gray'" @click="openModalInfo" :value="'Инструкция'" />
      </div>

      <template-list />

      <app-template-info ref="app-template-info" />
      <template-create ref="template-create" />
    </div>
    <div class="page-template__item page-template_sms">
      <div class="page-template__title section-title">Шаблоны sms</div>

      <div class="page-template__description section-description">
        Упревление шаблонами смс уведомлений.
      </div>
    </div>


  </div>
</template>

<script>
import templateList from '../components/template/template-list'
import appButton from "../components/base-ui/app-button";
import templateCreate from "../components/template/template-create";
import appTemplateInfo from '../components/template/app-template-info'

export default {
  name: 'template-message',
  components: {
    'template-create': templateCreate,
    "app-button": appButton,
    'template-list': templateList,
    'app-template-info': appTemplateInfo
  },
  methods: {
    openModalInfo: function () {
      this.$refs['app-template-info'].openModal()
    },
    openModalCreateTemplate: function () {
      this.$refs['template-create'].openModal();
    }
  },
}
</script>

<style lang="scss">
.page-template {
  display: flex;

  &__item {
    padding: 20px;
    width: 50%;
  }

  &_sms {
    border-left: 2px solid var(--color-border);
    pointer-events: none;
    opacity: .5;

    & * {
      pointer-events: none;
    }
  }

  &__btns {
    display: flex;
  }

  &__btn {
    margin-right: 20px;

    max-width: 340px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

}
</style>
