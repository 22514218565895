<template>
  <div class="pnnser-input__wrap">
    <input
        :type="typeResult ? typeResult : 'text'"
        :placeholder="placeholder? placeholder : ''"
        :name="name ? name : ''"
        :class="className"
        v-model="value"
        :value="value"
        class="pnnser-input"
        @input="inputChange"
    >
    <div
        v-if="name === 'password'"
        class="pnnser-input__switch"
        @click="switchPassword"
    >
      {{ switchValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-input',
  data: function () {
    return {
      switchValue: 'ABC',
      typeResult: '',
    }
  },
  methods: {
    inputChange: function () {
      this.emitValue();
    },
    emitValue: function () {
      this.$emit('input', this.value);
    },
    switchPassword: function () {
      if (this.typeResult == 'password') {
        this.typeResult = 'text';
        this.switchValue = '***';
        return;
      }
      if (this.typeResult == 'text') {
        this.typeResult = 'password';
        this.switchValue = `ABC`;
        return;
      }
    },
  },
  props: {
    type: {
      type: String,
    },
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    className: {
      type: String,
    }
  },
  mounted() {
    this.typeResult = this.type;
  },
}
</script>

<style lang="scss">
.pnnser-input {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  border: 2px solid var(--color-border);
  border-radius: 30px;
  background: #ffffff;
  font-size: 13px;
  &__wrap {
    position: relative;
  }
  &__switch {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }
}
</style>
