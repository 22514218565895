<!--Если передать id то форма обновит запись, в противном случае - создаст новую-->

<template>
  <div v-if="isOpenModal" @click="chooseCloseModal" class="template-create">
    <div class="template-create__content">

      <app-message class-name="template-create__message-result" ref="app-message" />

      <div class="template-create__header">
        <div class="template-create__title">
          Создание нового шаблона письма
        </div>
        <div @click="closeModal" class="template-create__close-btn">
          <img :src="closeImages" />
        </div>
      </div>
      <div class="template-create__form">
        <div class="template-create__form-title">
        </div>
        <div class="template-create__form-content">
          <app-editor v-model="formData.template" />
        </div>
      </div>
      <div class="template-create__footer">
        <app-input v-model="formData.title" :className="'template-create__input'" :placeholder="'Название'" />
        <app-button @click="saveForm" value="Сохранить" :className="'template-create__btn'" />
      </div>
    </div>
  </div>
</template>

<script>
import appEditor from '../app-aditor'
import appButton from "../base-ui/app-button";
import appInput from "../base-ui/app-input";
import appMessage from "../base-ui/app-message";

export default {
  name: 'template-create',
  components: {
    'app-message': appMessage,
    'app-input': appInput,
    'app-button': appButton,
    'app-editor': appEditor
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      closeImages: require('../../assets/icons/close.svg'),
      isOpenModal: false,
      formData: {
        title: '',
        template: ''
      }
    }
  },
  computed: {
    isUpdatedForm: function () {
      return Boolean(this.id);
    }
  },
  methods: {
    getDataTemplate: function () {
      const elemTemplate = this.$store.state.template.templateList.find(item => item.id === this.id);

      this.formData.template = elemTemplate.template;
      this.formData.title = elemTemplate.title;
    },
    chooseCloseModal: function (e) {
      if (e.target.classList.contains('template-create')) {
        this.closeModal()
      }
    },
    closeModal() {
      this.isOpenModal = false;
    },
    openModal() {
      this.isOpenModal = true;
    },
    createMessage: function (data) {
      this.$refs['app-message'].open({
        type: data.type,
        message: data.message,
        description: data.description || ''
      });
    },
    validateForm: function () {
      if (! this.formData.title) {
        this.createMessage({
          type: 'warning',
          message: 'Необходимо указать название'
        })
        return false;
      }

      if (! this.formData.template.includes('{{ productList }}')) {
        this.createMessage({
          type: 'warning',
          message: 'Письмо должно содержать переменную: {{ productList }}'
        })
        return false;
      }

      return true;
    },
    createTemplate: async function () {
      await this.$store.dispatch('createTemplate', {
        title: this.formData.title,
        template: this.formData.template
      })
          .then(() => {
            this.createMessage({
              type: 'success',
              message: 'Шаблон успешно добавлен'
            })
          })
          .catch(() => {
            this.createMessage({
              type: 'success',
              message: 'Шаблон успешно добавлен'
            })
          });
    },
    updateTemplate: async function () {
      await this.$store.dispatch('updateTemplate', {
        templateId: this.id,
        title: this.formData.title,
        template: this.formData.template
      })
          .then(response => {
            if (response && response.statusCode && response.statusCode === 401) {
              return this.$store.dispatch('logoutAccount')
            }

            this.createMessage({
              type: 'success',
              message: 'Шаблон успешно обнавлен'
            })
          })
          .catch(() => {
            this.createMessage({
              type: 'warning',
              message: 'Ошибка при сохранении. Повторите попытку'
            })
          });
    },
    saveForm: async function () {
      const isValidForm = this.validateForm();

      if (! isValidForm) {
        return;
      }

      if (this.isUpdatedForm) {
        await this.updateTemplate();
      } else {
        await this.createTemplate();
      }
    }
  },
  mounted() {
    if (this.isUpdatedForm) {
      this.getDataTemplate()
    }
  }
}
</script>

<style lang="scss">
.template-create {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .5);

  display: flex;
  justify-content: center;
  align-items: center;

  &__message-result {
    position: absolute;
    left: auto;
    top: auto;
    bottom: 30px;
    right: 30px;
  }

  &__message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1;
  }

  &__input {
    width: 360px;
    margin-right: 20px;
  }

  &__content {
    width: 90%;
    height: 90%;

    max-width: 1260px;

    background: #F8F8FB;

    border-radius: 30px;

    display: flex;
    flex-direction: column;

    position: relative;
  }

  &__header {
    padding: 30px;
    font-weight: 600;
    position: relative;
  }

  &__close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    & img {
      width: 25px;
    }
  }

  &__form {
    flex-grow: 1;

    border-bottom: 2px solid var(--color-border);
    border-top: 2px solid var(--color-border);

    display: flex;
    flex-direction: column;


    overflow: hidden;

    &-content {
      flex-grow: 1;
      height: 100%;
    }
  }

  &__footer {
    padding: 30px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    max-width: 320px;
  }
}
</style>
