<template>
  <div class="template-item__wrap">
    <div class="template-item">
      <div  class="template-item__header">
        <div class="template-item__title">{{ item.title }}</div>
        <div v-if="item.is_general" class="template-item__status" :class="itemList.find(elem => elem.is_active) ? 'template-item__status_error' : 'template-item__status_success'">{{ itemList.find(elem => elem.is_active) ? 'Не активный' : 'Активный' }}</div>
        <div v-else class="template-item__status" :class="item.is_active ? 'template-item__status_success' : 'template-item__status_error'">{{ item.is_active ? 'Активный' : 'Не активный' }}</div>
      </div>
      <div class="template-item__body">
        <div class="template-item__body-content">
          {{ item.template }}
        </div>
      </div>

      <div v-if="item.is_general" class="template-item__modal">
        <app-button @click="deactivateAll" value="Сделать шаблон активным" />
      </div>
      <div v-else class="template-item__modal">
        <app-button @click="activateTemplate" value="Сделать шаблон активным" />
        <app-button @click="openEditFormTemplate" value="Редактировать" />
        <app-button @click="deleteTemplate" value="Удалить" />
      </div>

    </div>

    <template-create ref="template-update" :id="item.id" />
    <app-message className="content-message" ref="app-message" />
  </div>
</template>

<script>
import templateCreate from '../template/template-create';
import appButton from "../base-ui/app-button";

export default {
  name: 'template-item',
  components: {
    'app-button': appButton,
    'template-create': templateCreate
  },
  props: {
    item: {
      type: Object
    },
    itemList: {
      type: Array
    }
  },
  methods: {
    openUpdateTemplate: function () {
      this.$refs['template-update'].openModal()
    },

    deactivateAll: function () {
      this.$store.dispatch('deactivateAll')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          this.$refs['app-message'].open({
            type: 'success',
            message: 'Шаблон успешно активирован'
          })
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'При активации шаблона произошла ошибка'
          })
        })
    },

    deleteTemplate: function () {
      this.$store.dispatch('deleteTemplate', {
        templateId: this.item.id
      })
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          this.$refs['app-message'].open({
            type: 'success',
            message: 'Шаблон успешно удален'
          })
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'При удалении шаблона произошла ошибка'
          })
        })
    },
    activateTemplate: function () {
      this.$store.dispatch('activateTemplate', {
        templateId: this.item.id
      })
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          this.$refs['app-message'].open({
            type: 'success',
            message: 'Шаблон успешно активирован'
          })
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'При активации шаблона произошла ошибка'
          })
        })
    },
    openEditFormTemplate: function () {
      this.openUpdateTemplate();
    }
  }
}
</script>

<style lang="scss">
.template-item {
  position: relative;
  width: 415px;
  border-radius: 30px;
  background: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  height: 230px;

  display: flex;
  flex-direction: column;

  &:hover {
    .template-item__modal {
      top: 0;
      transition: .5s;
    }
  }

  &__modal {
    position: absolute;
    transition: .5s;
    top: 450px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #7437fd33;
  }

  &__header {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #000000;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 25px 30px;
    border-bottom: 2px solid var(--color-border);
  }
  &__status {
    &_error {
      color: var(--color-red);
    }
    &_success {
      color: var(--color-green);
    }
  }



  &__body {
    padding: 25px 30px;
    min-height: 155px;
    overflow: hidden;
    flex-grow: 1;

    &-content {
      overflow: hidden;
      height: 100%;

      font-size: 15px;
      line-height: 128%;
    }
  }
}
</style>
