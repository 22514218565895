<template>
  <div class="editor">
    <AceEditor v-model="value" @input="emitValue" @init="editorInit" lang="html" theme="chrome" width="50%" height="100%"></AceEditor>
    <div style="width: 50%; height: 100%; overflow: scroll;" v-html="updatedContent"></div>
  </div>
</template>

<script>
export default {
  name: 'editor',
  components: {
    AceEditor: require('vue2-ace-editor')
  },
  methods: {
    emitValue: function () {
      this.$emit('input', this.value);
    },
    editorInit: function () {
      require('brace/mode/html')
      require('brace/theme/chrome')
      require('brace/snippets/javascript')
    },
    renderResult: function () {
      const links = `
        <ul>
          <li><a href="#">Рубашка с коротким рукавом Мюнхен XS / Серый</a></li>
          <li><a href="#">Куртка объемная с клапанами Копенгаген S / Черный</a></li>
        </ul>
      `;
      this.updatedContent = this.value
          .replace(/{{ productList }}/g, links)
          .replace(/{{productList}}/g, links)
    }
  },
  data: function () {
    return {
      updatedContent: ''
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
  },
  watch: {
    value: function () {
      this.renderResult();
    }
  },
  mounted() {
    this.renderResult();
  }
}
</script>

<style>
.editor {
  width: 100%;
  height: 100%;
  display: flex;
  background: #F8F8FB;
}
</style>
