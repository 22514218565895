<template>
  <div class="template-list">
    <template-item v-for="(item, index) in templateList" :key="index" :item="item" :itemList="templateList" />
    <app-message className="content-message" ref="app-message" />

  </div>
</template>

<script>
import templateItem from './template-item';

export default {
  name: 'template-list',
  computed: {
    templateList: function () {
      return this.$store.state.template.templateList;
    }
  },
  methods: {
    getTemplates: function () {
      this.$store.dispatch('getTemplates')
        .then(response => {
          if (response && response.statusCode && response.statusCode === 401) {
            return this.$store.dispatch('logoutAccount')
          }

          return response;
        })
        .catch(() => {
          this.$refs['app-message'].open({
            type: 'warning',
            message: 'Ошибка при загрузки существующих шаблонов'
          })
        })
    },

    getData: function () {
      if (! this.$store.state.template.templateList.length) {
        this.getTemplates()
      }
    }
  },
  components: {
    'template-item': templateItem
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
.template-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
