<template>
  <div @click="chooseCloseModal" class="template-info" :class="isOpenModal ? 'active': ''">
    <div class="template-info__content">
      <div @click="closeModal" class="template-info__close-btn">
        <img :src="closeImages" />
      </div>
      <div class="template-info__content-title">1. Создание шаблонов</div>
      <div class="template-info__content-content">
        <p>
          В шаблоне используется язык разметки HTML, благодаря чему вы можете создавать разнообразные шаблоны
          отличающиеся по врешнему виду и содержимому.
        </p>

        <p>
          При создании и редактировании шаблона вы можете видеть в реальном времени как визуально меняется шаблон вашего письма.
          Однако, различные почтовые клиенты могут отображать его иначе. Поэтому при создании шаблона,
          стоит следовать стандартным рекомендациям при верстки писем на языке html.
        </p>

        <p>
          Вот хороший материал на эту тему: <a href="//habr.com/ru/post/60420/">https://habr.com/ru/post/60420/</a>
        </p>
      </div>
      <div class="template-info__content-title">2. Динамическая информация</div>
      <div class="template-info__content-content">
        <p>
          Обязательным элементом любого письма является переменная <b>{{ variable }}</b>.
          При отправки пиьсма с уведомлением, на месте данной переменной будет отображен список
          товаров, которые появились в наличии.
        </p>

        <p>
          При создании письма, в визуальной части редактора, вы можете видеть пример того, как будет заменена переменная.
        </p>
      </div>
      <div class="template-info__content-title">3. Активны шаблон.</div>
      <div class="template-info__content-content">
        <p>
          Сколько бы шаблонов у вас не было, активным будет всегда один! Вы можеже активировать любой шаблон неограниченное кол-во раз.
          Именно из активного шаблона будет уходить письмо, при уведомлении пользователей о появлении товара/ов в наличии.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-template-info',
  data: function () {
    return {
      closeImages: require('../../assets/icons/close.svg'),
      variable: '{{ productList }}',
      isOpenModal: false
    }
  },
  methods: {
    chooseCloseModal: function (e) {
      if (e.target.classList.contains('template-info')) {
        this.closeModal()
      }
    },
    openModal: function () {
      this.isOpenModal = true;
    },
    closeModal: function () {
      this.isOpenModal = false;
    }
  }
}
</script>

<style lang="scss">
.template-info {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);

  display: none;
  justify-content: center;
  align-items: center;

  &.active {
    display: flex;
  }

  &__content {
    background: #fff;
    border-radius: 30px;
    padding: 30px;

    max-width: 700px;
    width: 94%;
    max-height: 90vh;
    overflow: scroll;

    position: relative;
    padding-top: 60px;
  }

  &__content-title {
    font-size: 18px;
    font-weight: 600;
  }

  &__close-btn {
    position: absolute;
    right: 30px;
    top: 30px;

    cursor: pointer;

    width: 20px;
    height: 20px;
    & img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
